
import { mapStores } from "pinia"

import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"
import { generateUuid } from "@evercam/shared/utils"
import { useProjectStore } from "@/stores/project"

import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import SitePlannerViewer from "@/components/sitePlanner/SitePlannerViewer"
import SitePlanCard from "@/components/sitePlanner/SitePlanCard.vue"
import SupportMessage from "@/components/SupportMessage"

export default {
  name: "SitePlannerProjectList",
  meta: {
    pageId: AnalyticsEventPageId.SitePlanner,
  },
  components: {
    EvercamLoadingAnimation,
    SitePlanCard,
    SitePlannerViewer,
    SupportMessage,
  },
  data: () => ({
    currentPage: 1,
    isAddingNewSitePlan: false,
    isLoading: true,
    pageSize: 12,
    filterQuery: "",
    sitePlanId: null,
    sitePlansList: [],
  }),
  computed: {
    ...mapStores(useProjectStore),
    addColClass() {
      return {
        "ml-0 pr-7 align-content-center": true,
        "ml-4 pl-3 mt-3": this.$vuetify.breakpoint.smAndDown,
      }
    },
    displayedSitePlansList() {
      const listToDisplay = this.filteredSitePlansList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      )

      return listToDisplay
    },
    filterColClass() {
      return {
        "pl-3 ml-4 pr-7": true,
      }
    },
    filteredSitePlansList() {
      const filteredList = this.filterQuery
        ? this.filterSitePlans()
        : [...this.sitePlansList]

      // Sort by most recent
      filteredList.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      )

      return filteredList
    },
    totalPages() {
      const pages = Math.ceil(this.filteredSitePlansList.length / this.pageSize)

      return pages
    },
  },
  watch: {
    "$route.query": {
      async handler(value) {
        const id = value.sitePlanId ?? value.site_plan_id

        if (!id) {
          this.filterQuery = ""
          this.isAddingNewSitePlan = false
          this.sitePlanId = null
          await this.fetchSitePlansList()
        } else {
          this.isAddingNewSitePlan = value.isNewSitePlan === "true"
          this.sitePlanId = id
          this.isLoading = false
        }
      },
      immediate: true,
    },
    filterQuery() {
      this.currentPage = 1
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.SitePlannerSitePlansList)

    // Clear selected project => This page is outside the projects scope
    if (this.projectStore.selectedProject) {
      this.projectStore.clearSelectedProject()
    }
  },
  onBeforeUnmount() {
    this.filterQuery = ""
    this.sitePlanId = null
    this.sitePlansList = []
    this.isAddingNewSitePlan = false
  },
  methods: {
    async onClickAddNewSitePlan() {
      this.$analytics.saveEvent(AnalyticsEvent.SitePlannerAddSitePlanClick)

      this.$router.push({
        query: {
          ...this.$route.query,
          isNewSitePlan: true,
          sitePlanId: await generateUuid(),
        },
      })
    },
    async onClickBackToList() {
      this.filterQuery = ""
      let query = { ...this.$route.query }

      delete query["sitePlanId"]
      delete query["site_plan_id"]
      delete query["isNewSitePlan"]
      delete query["is_new_sitePlan"]
      this.$router.push({
        query,
      })
    },
    onClickSitePlanCard(item) {
      this.$router.push({
        query: {
          ...this.$route.query,
          sitePlanId: item.projectId,
        },
      })
    },
    async fetchSitePlansList() {
      try {
        this.isLoading = true

        // Fetch the projects object from the firebase database
        const result = await fetch(
          `${this.$config.public.firebaseDbLink}/data/planner-list.json`,
          {
            method: "GET",
          }
        )

        // Parse the result to a JSON object
        const sitePlansObject = await result.json()

        if (!sitePlansObject) {
          return
        }

        // Convert projectsObject to a list
        this.sitePlansList =
          this.sitePlansListObjectToSitePlansList(sitePlansObject)
      } catch (e) {
        this.$notifications.error({
          text: "Error fetching site plans list.",
          error: e,
        })
      } finally {
        this.isLoading = false
      }
    },
    filterSitePlans() {
      return this.sitePlansList.filter(
        (sitePlan) =>
          sitePlan.projectName
            .toLowerCase()
            .includes(this.filterQuery.toLowerCase()) ||
          sitePlan.projectId
            .toLowerCase()
            .includes(this.filterQuery.toLowerCase()) ||
          sitePlan.projectLocation
            .toLowerCase()
            .includes(this.filterQuery.toLowerCase())
      )
    },
    sitePlansListObjectToSitePlansList(sitePlansObject) {
      if (!sitePlansObject) {
        return []
      }

      /**
       * Each field of projectsObject is a project
       * - So we map each field to a project object
       * - And add the sessionId to the project object
       */
      return Object.keys(sitePlansObject).map((projectId) => {
        return {
          ...sitePlansObject[projectId], // Project object properties
          projectId,
        }
      })
    },
  },
}
